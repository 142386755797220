<template>
    <div class="container">
        <navbar-admin/>
        <el-row class="menu-icons">
            <div class="icon" v-for="(menu,i) in filterMenus" :key="i">
                <span v-if="menu.id_menu_superior == null"> 
                    <el-button :class="`icon-${menu.icono} f-30 ${isActiveButton(menu.id)}`" @click="redirect(menu.id)" circle></el-button>
                </span>
            </div>
        </el-row>
        <div class="row my-3 mt-4">
            <div class="col-lg-12">
                <titulo-divisor titulo="Raíces" class="mx-0">
                    <div class="row">
                        <!-- <el-tooltip content="Añadir raíz de rutas" effect="light" placement="top" visible-arrow>
                            <div class="col-auto icon-option my-auto px-1">
                                <i class="icon-plus-circle f-30 cr-pointer" @click="createNewRaizRuta"/> 
                            </div>
                        </el-tooltip> -->
                        <tabs :tabs="tabsConfig" />
                    </div>
                </titulo-divisor>
            </div>
        </div>
        <tabla-permisos :heads='cabeceras' :rutasActive='rutasActive' />
        <!-- partials -->
        <modal-crear-new-raiz-ruta ref="createNewRaizRuta" @newRaiz="changeRaiz($event)"/>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import tablaPermisos from '../components/table.vue'
export default {
     components:{
        tablaPermisos,     
        ModalCrearNewRaizRuta: () => import('./../partials/modalCrearNewRaizRuta.vue'),
    },
    data(){
        return {
            tabsConfig: [],
            cabeceras: [
                {
                    id: 1,
                    nombre:'Acceso menú',
                    ruta:"configurar.permisos.acciones",
                    params: { id: 1 }
                },
                {
                    id: 2,
                    nombre:'Materiales',
                    ruta:"configurar.permisos.acciones",
                    params: { id: 2 }
                },
                {
                    id: 3,
                    nombre:'Categorías',
                    ruta:"configurar.permisos.acciones",
                    params: { id: 3 }
                },
                {
                    id: 4,
                    nombre:'Información',
                    ruta:"configurar.permisos.acciones",
                    params: { id: 4 }
                }
            ],
            rutasActive: false,
            // btnAdd: {
            //     isVisible: true,
            //     title: "Añadir ruta",
            //     type: 1 //ruta
            // },
            payload: {
                id_menu: null,
                tipo: 1 //ruta
            }
        }
    },
    computed: {
        ...mapGetters({
            menu: 'configurar/permisos/menus/menus',
            header: 'configurar/permisos/menus/headers',
            user_data: 'auth/user',
        }),
        menus: {
           get(){
             return this.menu
           },
           set(newName){
             return newName
           } 
        },
        headers: {
           get(){
             return this.header
           },
           set(newName){
             return newName
           } 
        },
        id(){ 
            return this.$route.params.id
        },
        tab(){ 
            return this.$route.params.tab
        },
        filterMenus(){
            let data = this.menus.filter(el => {
                return el.id_menu_superior == null
            })
            if (this.user_data.mostrar_configuracion == 1 && this.user_data.super_admin == 0) {
                data =  data.filter(el => {
                    const exists = this.user?.permiso_configuracion.find(e => `${e.nombre}`.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim() == `${el.nombre}`.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim())
                    if (exists) return el
                })
            }
            return data
        }
    },

    watch:{
        id(){
            this.getSubMenus()
        },
        tab(){
            this.payload.id_menu = this.tab;    
            this.handleGetHeaders()
        }
    },  
    async created(){
        await this.getMenus()
        this.getSubMenus()
        this.getBreadcumbs([ 'configurar.permisos', 'configurar.permisos.rutas' ]);
        await this.handleGetHeaders()
    }, 
    methods:{
        redirect(id){ 
            const idTab = this.getFirstSubMenu(id)
            this.$router.push({ name: 'configurar.permisos.rutas.detalle', params: { id, tab: idTab }})
        }, 
        ...mapActions({
            getMenus: 'configurar/permisos/menus/Action_get_menus',
            getHeaders: 'configurar/permisos/menus/Action_get_headers',
            getBreadcumbs: 'navigation/breadcumbs/getBreadcumbs',
        }),
        createNewRaizRuta(){
            this.$refs.createNewRaizRuta.toggle()
        },
        isActiveButton(id) {
            return this.id == id ? 'is_active' : ''
        },
        getSubMenus() {
            this.payload.id_menu = this.tab;
            this.tabsConfig = [];
            this.tabsConfig = this.menus.filter(el => el.id_menu_superior == this.id).map(element =>{ 
                return {
                    titulo: element.nombre,
                    ruta: 'configurar.permisos.rutas.detalle.grupo',
                    params: { tab: element.id }
                }
            })
        },
        getFirstSubMenu(id) {
            const first = this.menus.find(el => el.id_menu_superior == id)
            return first == null ? 0 : first.id             
        },
        async handleGetHeaders(){
            await this.getHeaders(this.payload)
            this.createHeaders()
        },
        async createHeaders() { 
            this.cabeceras = this.headers.map(element =>{ 
                return {
                    nombre: element.nombre,
                    ruta: element.name_ruta,
                    id: element.id
                }
            })
        },
        changeRaiz(value)
        {
            value.map(element =>{ 
                this.tabsConfig.push({
                    ruta: 'configurar.permisos.rutas.detalle.grupo',
                    titulo: element.nombre,
                    params: {
                        tab: element.id
                    }
                });
            })            
        }, 
    }
}
</script>

<style lang="scss" scoped>
.container {
    padding: 20px;
}
.menu-icons {
    display: flex;
}
.icon {
    margin: 0 5px 0 5px;
}
.el-button {
    color: #dddddd;
    line-height: normal;
    padding: 5px;
    &:hover {
        background-color: var(--color-general);
        color: var(--bg-navbar);
    }
}
.is_active {
    background-color: var(--color-general);
    color: var(--bg-navbar);
}
</style>